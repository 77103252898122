@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .border-x-0 {
    border-left-width: 0;
    border-right-width: 0;
  }
  .border-t-0 {
    border-top-width: 0;
  }
  .h-10 {
    height: 2.5rem;
  }
  .h-auto {
    height: auto;
  }
}

@import "./containers/components/index.css";

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}
