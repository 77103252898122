.ant-divider-horizontal.ant-divider-with-text::before {
  @apply border-gray-300;
}

.ant-divider-horizontal.ant-divider-with-text::after {
  @apply border-gray-300;
}

.ant-divider-plain.ant-divider-with-text {
  @apply text-sm text-gray-300 !important;
}
