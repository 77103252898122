.ant-notification-notice
  .ant-notification-notice-with-icon
  .ant-notification-notice-message {
  @apply text-base font-normal;
}

.ant-notification-notice
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  @apply text-sm font-normal;
}

.ant-notification-notice .ant-notification-notice-icon {
  @apply text-lg;
}
