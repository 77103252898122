.ant-menu-light.ant-menu-inline .ant-menu-item {
  @apply text-white;
}

.ant-menu-inline .ant-menu-submenu {
  @apply text-white;
}

.ant-menu-light .ant-menu-item-selected {
  @apply bg-white !text-primary-800;
}

.ant-menu-inline .ant-menu-submenu:hover {
  @apply text-white;
}
.ant-menu .ant-menu-item {
  @apply rounded-none;
}

.ant-menu .ant-menu-submenu-title {
  @apply rounded-none;
}
