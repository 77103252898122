.ant-btn {
  @apply flex items-center h-auto;
}

.ant-btn-default:not(:disabled):hover {
  @apply text-white bg-primary-600;
}

.ant-btn-primary {
  @apply !bg-primary-800;
}

.ant-btn-primary:hover {
  @apply !bg-primary-600;
}

.ant-btn-default.ant-btn-dangerous:not(:disabled):hover {
  @apply bg-transparent;
}
