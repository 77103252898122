.ant-modal .ant-modal-content {
  @apply w-120 px-8 pt-6 pb-9;
}

.ant-modal .ant-modal-content {
  @apply rounded-none;
}

.ant-form-item .ant-form-item-explain {
  @apply text-sm font-normal;
}

[class^="ant-typography"] {
  @apply text-sm;
}

/* .ant-divider-horizontal.ant-divider-with-text::before {
  @apply border-gray-900;
}

.ant-divider-horizontal.ant-divider-with-text::after {
  @apply border-gray-900;
}

.ant-divider-plain.ant-divider-with-text {
  @apply text-sm !important;
} */
