.radio-slip > .ant-radio-group.ant-radio-group-solid {
  @apply flex;
}

.radio-slip
  > .ant-radio-group.ant-radio-group-solid
  > .ant-radio-button-wrapper {
  @apply h-[2.5rem] w-[7.5rem] rounded-[1.25rem] flex justify-center items-center border-[0];
}

.radio-slip
  > .ant-radio-group.ant-radio-group-solid
  > .ant-radio-button-wrapper::before {
  @apply bg-transparent;
}

.radio-slip
  > .ant-radio-group.ant-radio-group-solid
  > .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  @apply bg-transparent;
}

/* .radio-label-slip */
