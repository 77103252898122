.ant-input {
  @apply rounded-md border-primary-800;
}

.ant-input-affix-wrapper > input.ant-input {
  @apply py-1;
}

.ant-input:focus {
  border-color: #588d3f;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(88, 141, 63, 0.5);
  box-shadow: 0 0 0 2px rgba(88, 141, 63, 0.5);
}
