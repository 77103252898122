.ant-steps-item-title {
  @apply text-white !important;
}

.ant-steps .ant-steps-item-title {
  @apply text-base font-normal;
}

.ant-steps.ant-steps-label-vertical .ant-steps-item-tail {
  @apply ml-16 py-3 px-14;
}

.ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  @apply bg-gray-500;
}
