.ant-layout {
  @apply min-h-screen;
}

.ant-layout-header {
  @apply bg-white h-auto;
}

.ant-layout-content {
  @apply flex items-center relative;
}

.layout-header {
  @apply flex justify-between items-center;
}

.ant-layout-footer {
  @apply bg-primary-900;
}

.ant-layout .ant-layout-sider {
  @apply bg-primary-900;
}

.ant-layout .ant-layout-sider-trigger {
  @apply bg-primary-800;
}

.ant-menu-light {
  @apply bg-primary-900;
}
