.ant-badge .ant-scroll-number .ant-scroll-number-only {
  @apply text-[0.6rem];
}

.ant-badge .ant-badge-count-sm {
  @apply leading-[0.875rem];
}

.ant-badge .ant-badge-multiple-words {
  @apply p-0;
}
